<template>
    <div>
        <!-- basic modal -->
        <b-modal id="detailPekerjaan"
            :title="'Detail Presensi ' + detailPresensi.nm_hari +' '+detailPresensi.tgl_presensi" size="lg" hide-footer
            content-class="shadow" ok-title="Keluar">
            <!-- <b-row>
                <b-col md="12">
                    <b-card variant="warning" >
                        <div class="alert-body">
                            <b-row>
                                <b-col md="2">Nama </b-col>
                                <b-col md="4">: <strong>{{profilUser.nm_lengkap  }}</strong></b-col>
                                <b-col md="2">Kelompok</b-col>
                                <b-col md="4">: <strong>{{  profilUser.nama_kelompok}}</strong></b-col>
                            </b-row>

                            <b-row>
                                <b-col md="2">Email </b-col>
                                <b-col md="4">: <strong>{{profilUser.email  }}</strong></b-col>
                                <b-col md="2">Bidang</b-col>
                                <b-col md="4">: <strong>{{  profilUser.nama_bidang}}</strong></b-col>
                            </b-row>

                            <b-row>
                                <b-col md="2">No Hp </b-col>
                                <b-col md="4">: <strong>{{profilUser.no_hp  }}</strong></b-col>
                                <b-col md="2">Jabatan</b-col>
                                <b-col md="4">: <strong>{{  profilUser.nama_jabatan}}</strong></b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-col>
            </b-row> -->

            <b-row>
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col md="7">
                                <h6>
                                    <b-avatar size="20px" rounded="" variant="light-info" class="mr-1">
                                        <feather-icon icon="MapIcon" size="15" />
                                    </b-avatar><b>Lokasi {{  detailPresensi.nama_kordinat }}</b> <small></small>
                                    <br><small>{{ detailPresensi.keterangan }}</small>
                                </h6>
                                <a :href="'https://www.google.com/maps/dir/' + detailPresensi.kordinat_masuk +'/'+ detailPresensi.kordinat"
                                    target="_blank">
                                    <b-avatar size="20px" rounded="" variant="light-info" class="mr-1"
                                        v-if="detailPresensi.st_kor_masuk !='Diluar radius presensi'">
                                        <feather-icon icon="RadioIcon" size="15" />
                                    </b-avatar>

                                    <b-avatar size="20px" rounded="" variant="light-danger" class="mr-1"
                                        v-if="detailPresensi.st_kor_masuk =='Diluar radius presensi'">
                                        <feather-icon icon="RadioIcon" size="15" />
                                    </b-avatar>


                                    <small> Radius Presensi Masuk <b>{{ detailPresensi.radius_masuk }} KM</b> Dari
                                        Central
                                        Koordinat
                                    </small>
                                    <br>

                                </a>
                                <a :href="'https://www.google.com/maps/dir/' + detailPresensi.kordinat_pulang +'/'+ detailPresensi.kordinat"
                                    target="_blank" v-if=" detailPresensi.jam_pulang != null ">
                                    <b-avatar size="20px" rounded="" variant="light-info" class="mr-1"
                                        v-if="detailPresensi.st_kor_pulang !='Diluar radius presensi'">
                                        <feather-icon icon="RadioIcon" size="15" />
                                    </b-avatar>

                                    <b-avatar size="20px" rounded="" variant="light-danger" class="mr-1"
                                        v-if="detailPresensi.st_kor_pulang =='Diluar radius presensi'">
                                        <feather-icon icon="RadioIcon" size="15" />
                                    </b-avatar>
                                    <small> Radius Presensi Pulang <b>{{ detailPresensi.radius_pulang }} KM</b> Dari
                                        Central
                                        Koordinat

                                    </small>
                                </a>
                            </b-col>
                            <b-col md="5">
                                <small>
                                    <b-avatar size="20px" rounded="" variant="light-info" class="mr-1">
                                        <feather-icon icon="ClockIcon" size="15" />
                                    </b-avatar>Masuk <b>{{ detailPresensi.jam_masuk }}</b>,
                                    <span v-show="detailPresensi.jam_pulang != null "> Pulang
                                        <b>{{ detailPresensi.jam_pulang }} </b></span>
                                    <br>

                                    <span v-show="detailPresensi.jam_pulang != null ">
                                        <b-avatar size="20px" rounded="" variant="light-info" class="mr-1">
                                            <feather-icon icon="SettingsIcon" size="15" />
                                        </b-avatar> Durasi kerja
                                        <b>{{detailPresensi.durasi_kerja}} Jam Bekerja</b>
                                    </span>
                                </small> <br>
                                <b-badge variant="light-warning" v-if="detailPresensi.status_presensi =='Terlambat'"
                                    class="d-block mb-1">
                                    Terlambat Presensi Masuk
                                </b-badge>
                                <b-badge variant="light-primary" v-if="detailPresensi.st_durasi =='Terpenuhi'"
                                    class="d-block">
                                    Durasi jam kerja terpenuhi >= {{ profilUser.durasi_kerja }} jam
                                </b-badge>

                                <b-badge variant="light-danger" v-if="detailPresensi.st_durasi !='Terpenuhi'"
                                    class="d-block">
                                    Tidak Terpenuhi kurang dari {{ profilUser.durasi_kerja }} Jam kerja
                                </b-badge>

                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">
                    <b-card>
                        <h6>Foto Masuk</h6> 
                        <b-avatar size="100%" rounded=""
                            :src="detailPresensi.foto_masuk" />
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card v-show="detailPresensi.foto_pulang != null ">
                        <h6>Foto Pulang</h6> 
                        <b-avatar size="100%" rounded=""
                            :src="detailPresensi.foto_pulang" />
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-card>
                        <h3>Laporan Pekerjaan</h3> 
                        <div v-for="lapker in detailPresensi.laporan_kerja " v-bind:key="lapker" class="mt-1"> 
                            <h5><feather-icon icon="AlignJustifyIcon" size="25" />   {{ lapker.judul }}</h5>
                            <p>{{  lapker.keterangan}}</p>
                            <b-carousel id="carousel-example-generic" controls indicators>
                                <b-carousel-slide :img-src="lapker.foto_kegiatan" />
                            </b-carousel> 
                        </div>
                    </b-card>
                </b-col>
            </b-row> 
        </b-modal>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';


    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BCard,
        BAvatar,
        BBadge,
        BCarousel,
        BCarouselSlide

    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BModal,
            BButton,
            VBModal,
            BAlert,
            BRow,
            BCol,
            BCard,
            BAvatar,
            BBadge,
            BCarousel,
            BCarouselSlide
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            detailPresensi: {},
            profilUser: {}
        },
        mixins: [Base],
        emits: ["simpan_kordinat"],
        data() {
            return {}
        },
        methods: {
            Batalkan() {
                this.$root.$emit('bv::hide::modal', 'TambahKordinat')
            }
        }
    }
</script>

<style lang="scss">
    .bg {
        background: #a3d3ff4d;
    }
</style>