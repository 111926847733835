<template>
  <div>
    {{ id_anggota }} || {{ date }}
    <profil-user :profilUser="profilUser" :tanggal=tanggal></profil-user>

    <b-row>
      <b-col md="12">
        <b-card>
          <list-presensi-vue :items="items.rs" :profilUser="profilUser" @detailData=detailData></list-presensi-vue>
        </b-card>
      </b-col>
    </b-row>

    <detailpekerjaan :detailPresensi='detailPresensi' :profilUser="profilUser"></detailpekerjaan>
  </div>
</template>

<script>
  import axios from '@/config/Axios'
  import Base from '@/config/Mixins_base'
  import {
    BRow,
    BCol,
    BCard,
    BModal,
    VBModal,
    BAlert
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import Detailpekerjaan from './detailpekerjaan.vue'
  import profilUser from './profilUser.vue'
  import listPresensiVue from './listPresensi.vue'

  export default {
    mixins: [Base],
    components: {
      BRow,
      BCol,
      BCard,
      BModal,
      VBModal,
      BAlert,
      Detailpekerjaan,
      profilUser,
      listPresensiVue
    },
    data() {
      return {
        id_anggota: "",
        date: "",
        user_key: "",
        profilUser: {},
        items: {},
        tanggal: "",
        detailPresensi: {}
      }
    },
    mounted() {
      const self = this;
      self.id_anggota = self.$route.params.id_anggota,
      self.date = self.$route.params.date,
      self.user_key = self.$route.params.user_key,
      self.loda_profil()
      self.load_histori_presensi(),
      self.tgl_now();
    },
    methods: {
      tgl_now() {
        const nm_bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus',
          'September', 'Oktober', 'November', 'Desember'
        ]
        const ddk = new Date(this.date)
        this.tanggal = 'Bulan ' + nm_bulan[ddk.getMonth()] + ', ' + ddk.getFullYear();
      },

      async loda_profil() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/anggota/profil_user',
            data: {
              id_anggota: self.$route.params.id_anggota,
              tgl: self.$route.params.date
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.profilUser = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_histori_presensi() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/anggota/load_histori_presensi',
            data: {
              id_anggota: self.$route.params.id_anggota,
              tanggal: self.$route.params.date
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      detailData(presensi) {
        this.detailPresensi = presensi
      }
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },


  }
</script>

<style>

</style>